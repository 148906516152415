* {
  box-sizing: border-box;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  color: #ccc;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}
.wrapper {
  max-width: 1000px;
  overflow: hidden;
  margin: auto;
}
.App {
  text-align: center;
  background-color: #030708;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.menu {
  color: yellow;
  margin-right: 2rem;
  font-size: 2.5em;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo {
  height: 150px;
}

.hero-img {
  width: 100%;
}

.usefulLinksContainer,
.testimonialsContainer {
  width: 100%;
  padding-top: 2rem;
}
.usefulLinksContainer ul {
  list-style: none;
}
.usefulLinksContainer ul li {
  border: 1px dashed yellow;
  border-radius: 10px;
  width: 60%;
  margin: 1.5rem auto;
  padding: 1rem;
}
.testimonialsCarrousel {
  display: flex;
  flex-direction: column;
}
.testimonialContainer {
  height: 300px;
  width: 300px;
  margin: auto;
  border-radius: 20px;
  margin-top: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.testimonialContainer::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 4000px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    yellow
  );
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.testimonialBox {
  position: absolute;
  height: 295px;
  width: 295px;
  padding: 1rem;
  border-radius: 20px;
  background-color: #0c0d0d;
  position: absolute;
}
.testimonialBox p {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-style: normal;
}
.testimonialBox h5 {
  font-style: italic;
  font-size: 1.3rem;
}
.message {
  font-style: italic;
  font-weight: 300;
}
.newDriver {
  padding: 1rem;
  text-align: right;
}
.PriceContainer {
  margin: 2rem;
}
.PriceContainer h2 {
  margin-bottom: 2rem;
}
.PriceContainer li {
  padding-left: 25%;
  text-align: left;
  list-style: none;
  margin-bottom: 0.5rem;
}
.policeContainer {
  margin: 3rem;
}

.policeContainer h1 {
  margin: 1.5rem;
}

.policeContainer h2,
h3,
h4 {
  text-align: left;
  margin: 1rem;
}

.policeContainer ul li {
  text-align: left;
  margin: 0.5rem;
}
